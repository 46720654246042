import {
  ERROR_CREATING_TICKET,
  ERROR_SERVICE,
  ERROR_UNAUTHORIZED,
  LOGIN_URL,
} from "../constants/constants";

export const getCounties = async () => {
  try {
    const response = await fetch(`${LOGIN_URL}/geolocation/states`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return ERROR_SERVICE;
    }
    return await response.json();
  } catch (e) {
    return ERROR_SERVICE;
  }
};

export const getAddresses = async (id: number) => {
  try {
    const response = await fetch(`${LOGIN_URL}/geolocation/address/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return ERROR_SERVICE;
    }
    return await response.json();
  } catch (e) {
    return ERROR_SERVICE;
  }
};

export const getProperties = async (token: string, id?: number) => {
  try {
    if (!token) {
      return ERROR_UNAUTHORIZED;
    }
    const finalURL = id
      ? `${LOGIN_URL}/property/${id}`
      : `${LOGIN_URL}/property`;
    const response = await fetch(finalURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return ERROR_SERVICE;
    }
    return await response.json();
  } catch (e) {
    return ERROR_SERVICE;
  }
};

export const getTicketsFromProperty = async (
  token: string | null,
  propertyId: number
) => {
  try {
    if (!token) {
      return ERROR_UNAUTHORIZED;
    }
    const response = await fetch(`${LOGIN_URL}/ticket/user/${propertyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return ERROR_SERVICE;
    }
    return await response.json();
  } catch (e) {
    return ERROR_SERVICE;
  }
};

export const createTicket = async (body: any, token: string) => {
  try {
    if (!token) {
      return ERROR_UNAUTHORIZED;
    }
    const formData = new FormData();
    formData.append("location", body.location);
    formData.append("module", body.visit.module);
    formData.append("date", body.visit.date);
    body.remarks.forEach((remark: any) => {
      formData.append("remarks[]", remark.description);
      formData.append("filesQty[]", remark.imageQuantity);
      formData.append("sites[]", remark.site);
      remark.images.forEach((image: any) => {
        formData.append("files", image[0]);
      });
    });

    const response = await fetch(`${LOGIN_URL}/ticket`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (!response.ok) {
      return ERROR_CREATING_TICKET;
    }
    return await response.json();
  } catch (e) {
    return ERROR_CREATING_TICKET;
  }
};
